/* Background Responsivo */
.backgroundContainer {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    overflow: hidden;
    z-index: -1;
}
.responsiveImg {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1080px){
    #backMob{
        display: block;
    }

    #backDesk{
        display: none;
    }
}

@media screen and (min-width: 1081px){
    #backMob{
        display: none;
    }
    
    #backDesk{
        display: block;
    }
}