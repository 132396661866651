.container_formlogin{
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    position: relative;
    text-align: center;
    font-size: 1rem;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 1;
    border: solid 0.4rem transparent;
    border-radius: 2rem;
    background:
    linear-gradient( #fff, #fff) padding-box,
    linear-gradient(90deg, #EFBF14, #000) border-box;
}

.form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../imgs/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3;
    z-index: -1;
}

@media screen and (min-width: 320px) {
    .form {
        padding: 2.4rem;
    }
}

@media screen and (min-width: 400px) {
    .form {
        padding: 3.4rem;
    }
}

/* Style do Texto de Cima */
.textcenter{
    text-align: center;
    padding: 0.3rem;
    background-color: #000;
    border: 0;
    color: #fff;
    border-radius: 0.4rem;
    margin-bottom: 2rem;
}

/* Style Botão */
.btn{
    background-color: #000;
    color: #fff;
    border-radius: 3rem;
    border: 0;
    padding: 0.4rem 3rem 0.4rem 3rem;
    cursor: pointer;
    margin-bottom: 1rem;
}
.btn:hover{
    transition: 0.5s;
    background-color: #f5f5f5;
    color: #000;
}

/* Style dos Inputs */
.input{
    font-size: 1rem;
    margin-bottom: 2rem;
    background: none;
    padding: 0.6rem 0.6rem 0.6rem 0;

    outline: none;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-color: #000;
}
input::placeholder{
    color: #000;
}

.label{
    font-size: 1.3rem;
}

.esquerda{
    text-align: left;
}