@media screen and (min-width: 320px){
    .display_grid{
        grid-template-columns: auto;
        margin-top: 5rem;
    }
}

@media screen and (min-width: 720px){
    .display_grid{
        grid-template-columns: repeat(2, 1fr);
        margin-top: 1rem;
    }
}

.container_adm{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    height: auto;
}
.display_grid{
    display: grid;
}

/* Estilo dos Cards */
img{
    width: 10rem;
    height: 10rem;
}

.cards_adm{
    background-color: #999999;
    font-size: 0.5rem;
    text-align: center;
    margin: 2rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: #FBD034 solid 0.1rem;
    cursor: pointer;
}