@media screen and (min-width: 320px){
    .containerBtnVoltar{
        display: none;
    }
}

@media screen and (min-width: 720px){
    .containerBtnVoltar{
        display: flex;
    }
}

.containerBtnVoltar{
    justify-content: center;
    align-items: center;
    cursor: pointer;

    z-index: 1000;
    position: fixed;
    top: 1rem;
    left: 7rem;

    padding: 0.5rem;
    border: #fff 0.2rem solid;
    border-radius: 1rem;

    background-color: #FBD034;
    color: #000;
}

.setaImg{
    width: 1rem;
    height: 1rem;
    margin-right: 0.8rem;
}