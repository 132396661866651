/* Responsividade */
@media screen and (max-width: 1080px) {
    /* Estilo das telas principais */
    .form{
        width: 20rem;
        height: 30rem;
        overflow-x: hidden; 
        overflow-y: scroll; 
    }

    .container_inputs{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .textcenter{
        font-size: 0.8rem;
    }

    /* Estilo Botão */
    button{
        margin-bottom: 1rem;
    }

    /* Estilo Radio */
    .divRadio{
        margin: 0 1.2rem 1.6rem 1.2rem;
    }
    
    .labelTextCenter{
        text-align: center;
        margin-bottom: 0.5rem;
    }
    
    .opcoes{
      display: flex;
      justify-content: space-around;
      text-align: center;
    }

    .escolha{
      width: 5.6rem;
      height: 3rem;
      font-size: 0.9rem;
    }

    /* Estilo Escolha dos Dias da Semana*/
    .divEscolhaDia{
        grid-template-columns: auto;
        gap: 1rem;
    }

    /* Estilo do Contrato */
    .divContrato {
        max-width: 18rem;
    }
}

@media screen and (min-width: 1081px) {
    /* Estilo das telas principais */
    .form{
        width: 50rem;
        height: 30rem;
    }

    .container_inputs{
        display: grid;
        min-width: 28.9rem;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 1rem;
    }

    .textcenter{
        font-size: 0.9rem;
    }

    /* Estilo Radio */
    .divRadio{
        margin: 0 1.2rem 1.6rem 1.2rem;
    }
    
    .labelTextCenter{
        display: flex;
        justify-content: center;
        margin-bottom: 0.5rem;
    }
    
    .opcoes{
      display: flex;
      justify-content: space-around;
      text-align: center;
    }
    
    .escolha{
      width: 8rem;
      height: 3.5rem;
      font-size: 1rem;
    }

    /* Estilo Escolha dos Dias da Semana*/
    .divEscolhaDia{
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem 10rem;
    }

    /* Estilo do Contrato */
    .divContrato {
        max-width: 30rem;
    }
}

/* Estilo Principal */
.container{
    width: 100vw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    background-color: #fff;
    border: solid 0.4rem transparent;
    border-radius: 2rem;
    background:
        linear-gradient( #fff, #fff) padding-box,
        linear-gradient(90deg, #EFBF14, #000) border-box;
}

.textcenter{
    width: 30rem;
    padding: 0.3rem;
    background-color: #000;
    color: #fff;
    border-radius: 0.4rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.centro{
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* Personaliza o tamanho da barra de rolagem */
.form::-webkit-scrollbar {
    display: none;
}

/* Estilo do Input de Radio */
.radioStyle input[type="radio"] {
    display: none;
}
.radioStyle input[type="radio"]:checked + label {
    background-color: #EFBF14;
}

.divRadio{
    margin: 0 1.2rem 1.6rem 1.2rem;
}

.labelTextCenter{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.opcoes{
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.escolha{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  border: #000 solid 0.1rem;
}

/* Estilo Botão */
.button{
    width: 15rem;
    background-color: #EFBF14;
    color: #000;
    border: none;
    border-radius: 2rem;
    padding: 0.5rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.button:hover{
    background-color: #efaa14;
}

.divBotao{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
}

.iconNavegar{
    margin: 0 0.2rem 0 0.2rem;
    width: 1rem;
    height: 1rem;
}

/* Estilos dos Passos */
.SimOuNao{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.container_Passo8{
    display: flex;
    flex-direction: column;
    user-select: none;
}

/* Estilo dos Selects */
.container_Passo_Escolhas {
    user-select: none;
}

.divSelect {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.select {
    appearance: none;
    cursor: pointer;

    text-align: center;
    font-size: 1rem;
    text-overflow: ellipsis;
    
    background:
        linear-gradient(#fff, #fff) padding-box,
        linear-gradient(90deg, #EFBF14, #000) border-box;
    border-radius: 2rem;
    border: 0.2rem solid transparent;

    padding: 1rem;
    min-width: 30rem;
    max-width: 30rem;
    
    white-space: nowrap;
    overflow: hidden;
}

.select:focus {
    outline: none;
    border-color: transparent;
}

option{
    background-color: #EFBF14;
    color: #000;
}

/* Estilo Escolha dos Dias da Semana */
.divEscolhaDia{
    display: grid;
    font-size: 1.2rem;
}

.divEscolhaDia input[type="radio"] {
    transform: scale(1.5); /* Aumenta o tamanho */
    margin: 0.5rem; /* Espaçamento ao redor do checkbox */
}

/* Estilo Escolha de Turma */
.divEscolhaTurma {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    max-height: 15rem;
    overflow-y: auto;
    padding-right: 1rem;
}

input[type="checkbox"] {
    transform: scale(1.5); /* Aumenta o tamanho */
    margin: 0.5rem; /* Espaçamento ao redor do checkbox */
}

.divEscolhaTurma::-webkit-scrollbar {
    width: 8px;
}

.divEscolhaTurma::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

/* Estilo do Contrato */
.divContrato {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
  
.contrato {
    max-height: 15rem;
    overflow-y: scroll;
    padding: 1rem;
    border: 0.1rem solid #000;
    background-color: #fff;
    line-height: 1.5;
}

.contratoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 2rem;
}

.linkContrato {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #EFBF14;
    text-decoration: none;
    background-color: #383838;
    padding: 0.8rem 1.2rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s, transform 0.2s;
}
.linkContrato:hover {
    transform: translateY(-2px);
}