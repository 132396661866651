@media screen and (min-width: 320px){
    .textcenter{
        margin-top: 5rem;
    }

    .form{
        padding: 1rem 0;
        width: 85%;
    }

    .contentInputs{
        grid-template-columns: auto;
    }
}

@media screen and (min-width: 720px){
    .textcenter{
        background-color: black;
        padding: 0.5rem;
        border-radius: 0.5rem;
        margin-top: 2rem;
    }

    .form{
        padding: 1rem 0 1rem 0.7rem;
        width: auto;
    }

    .contentInputs{
        grid-template-columns: repeat(3, 2fr);
    }
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form{
    background-color: #999999;
    border-radius: 0.6rem;
    border: 0.2rem solid #FBD034;
}

.contentInputs {
    display: grid;
}
.contentInputs > * {
    justify-self: center; /* Alinha individualmente cada item no centro da célula */
}

/* Style do Texto de Cima */
.textcenter{
    text-align: center;
    color: #fff;
    margin-bottom: 2rem;
    font-size: 0.9rem;
}

/* Style Do Botão */
.divBtn{
    display: flex;
    justify-content: center;
}

.btn{
    background-color: #000;
    color: #fff;
    border-radius: 3rem;
    border: 0;
    padding: 0.4rem 2.8rem 0.4rem 2.8rem;
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: 0.8rem;
}
.btn:hover{
    transition: 0.5s;
    background-color: #f5f5f5;
    color: #000;
}