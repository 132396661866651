@media screen and (min-width: 320px){
    .container_alunoscalendario{
        width: 100%;
        margin-top: 5rem;

        border: 0;
        border-radius: 0;
        background-color: #2b2b2b;
    }

    .lista{
        margin: 0.4rem;
    }

    .btnChamada{
        margin: 0 0 0 0.5rem;
    }
}

@media screen and (min-width: 720px){
    .container_alunoscalendario{
        width: 50%;
        min-height: 70vh;
        height: auto;
        margin-top: 5rem;

        border: 0.1rem #FBD034 solid;
        border-radius: 0.5rem;
        background-color: #2b2b2b;
    }

    .lista{
        margin: 1rem;
    }

    .btnChamada{
        margin: 0.5rem 0 0 1rem;
    }
}

.ajuste{
    display: flex;
    justify-content: center;
}

.textTurma{
    margin: 0.4rem;
    font-size: 1.8rem;
}
.textLH{
    margin:  0.4rem;
    font-size: 1.2rem;
}

.lista{
    line-height: 1.8rem;
    font-size: 1.2rem;
    color: #ccc;
}

.iconExcluir{
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2rem;
    border: none;
}
.iconExcluir:hover{
    background-color: #FBD034;
}

li{
    display: flex;
    align-items: center;
    border: #ccc solid 0.1rem;
}

/* Butão Ir pra Chamada */
.btnChamada{
    border: 0.1rem #FBD034 solid;
    border-radius: 1rem;
    background-color: #1e1e1e;
    color: #fff;
    padding: 1rem;
    cursor: pointer;
}
.btnChamada:hover{
    background-color: #000;
    transition: 0.5s;
}