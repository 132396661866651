@media screen and (min-width: 320px){
    .containerFiltro{
        top: 6rem;
        right: 1rem;
    }
    
    .caixa{
        right: 0;
        max-width: 80vw;
    }
}

@media screen and (min-width: 720px){
    .containerFiltro{
        top: 2rem;
        left: 7rem;
    }

    .caixa{
        left: -1rem;
        max-width: 20vw;
    }
}

.containerFiltro {
    user-select: none;
    position: fixed;
    z-index: 1000;
}

.containerFiltro, h2{
    cursor: pointer;
}

.caixa {
    position: absolute;
    bottom: auto;
    top: 2rem;

    background-color: #999999;
    border: 0.1rem solid #FBD034;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 20rem;
    visibility: hidden;
}

.mostrar {
    visibility: visible;
}
