@media screen and (min-width: 320px){
    .container_chamada{
        width: 100%;
        border: 0;
        border-radius: 0;
        flex-direction: column;
    }

    .tabela{
        min-width: 90%;
        max-width: 90%;
    }

    .divObservacao{
        width: 100%;
        margin: 1rem 0 0 0;
    }

    .inputData{
        width: 90%;
        margin-left: 0.5rem;
    }

    .labelObservacao{
        margin: 1rem 0 0.5rem 0.5rem;
    }

    .inputObservacao{
        width: 90%;
        min-height: 10rem;
        margin-left: 0.5rem;
    }
}

@media screen and (min-width: 720px){
    .container_chamada{
        width: 50%;
        border: 0.1rem #FBD034 solid;
        border-radius: 0.5rem;
        flex-direction: row;
        align-items: flex-start; /* Alinha no topo */
    }

    .tabela{
       min-width: 70%;
       max-width: 70%;
    }
    
    .divObservacao{
        width: 30%;
        margin: 0.8rem 1rem 1rem 0;
    }

    .inputData{
        margin-left: 0;
    }

    .labelObservacao{
        margin: 1rem 0;
    }

    .inputObservacao{
        margin-left: 0;
        min-height: 20rem;
    }
}

.container_chamada{
    display: flex;
    min-height: 70vh;
    height: auto;
    margin-top: 5rem;
    background-color: #2b2b2b;
}

.ajuste{
    display: flex;
    justify-content: center;
}

/* Estilo da Tabela */
.tabela{
    margin: 1rem;
    border-collapse: collapse;
}

.colunaPresenca{
    text-align: center;
    width: 15%;
}

.colunaNome{
    width: 85%;
}

/* Estilo da parte da Observação */
.divObservacao{
    display: flex;
    flex-direction: column;
}

.status{
    color: #fff;
    margin: 0 0 1rem 0;
}
.vermelho{
    color: #f00;
}
.azul{
    color: #00ff0d;
}

.inputData {
    padding: 0.5rem;
    border: 0.1rem solid #FBD034;
    border-radius: 0.5rem;
    background-color: #1e1e1e;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
}
.inputData::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
}

.labelObservacao{
    font-size: 1.2rem;
}

.inputObservacao{
    padding: 0.5rem;
    border: 0.1rem solid #FBD034;
    border-radius: 0.5rem;
    background-color: #1e1e1e;
    color: white;
    line-height: 1.5;
    outline: none;
}

.divbtnEnviar{
    display: flex;
    justify-content: center;
}

.btnEnviarChamada{
    border: 0.1rem #FBD034 solid;
    border-radius: 2rem;
    background-color: #1e1e1e;
    color: #fff;
    width: 80%;
    height: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
}
.btnEnviarChamada:hover{
    background-color: #000;
    transition: 0.5s;
}