.margin_content a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    border: none; 
    padding: 0;
    margin: 0;
  }

@media screen and (min-width: 320px){
    .container_calendario{
        margin: 0.2rem 1.3rem 2rem 1.3rem;
    }
    .textDia{
        margin-left: 2rem;
    }
    .div_helloworld{
        width: 100vw;
        height: 90vh;
        margin-top: -4rem;
        margin-left: 0;
    }
    .margin_content{
        margin-top: 4rem;
        margin-left: 0;
    }
    
    .marginTop{
        margin-top: 3rem;
    }
}

@media screen and (min-width: 720px){
    .container_calendario{
        margin: 0rem 3rem 1rem 3rem;
        height: 7rem;
    }
    .textDia{
        margin-left: 3.5rem;
    }
    .div_helloworld{
        width: 100%;
        height: 100vh;
        margin-left: -2rem;
        margin-top: 0;
    }
    .margin_content{
        margin-left: 4rem;
        margin-top: 0;
    }
    
    .marginTop{
        margin-top: 4rem;
    }
}

.container_calendario{
    border: 0.1rem #FBD034 solid;
    border-radius: 0.5rem;
    background-color: #2b2b2b;
    cursor: pointer;
}

/* Textos da Div */
.textDia{
    font-size: 1.5rem;
    margin-top: 2.5rem;
    margin-bottom: 0.6rem;
}
.textTurma{
    margin: 0.4rem;
    font-size: 1.6rem;
}
.textLH{
    margin:  0.4rem;
    font-size: 1rem;
}

/* Tela de Carregamento */
.div_helloworld{
    display: flex;
    justify-content: center;
    align-items: center;
}
.helloworld{
    font-size: 2.5rem;
    text-align: center;
}